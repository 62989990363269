import React, { useState, useEffect, useRef } from 'react';
import Illustration from 'components/Illustration';
import Button from 'components/Button'
import BoredAPI from '../api';
import BoredAPIResponse, { getClearResponse } from '../api/Response';
import PricePoint from '../components/PricePoint';
import AccessibilityPoint from '../components/AccessibilityPoint';
import Description from '../components/Description';
import {CSSTransition } from "react-transition-group";

function Home() {
    let categories = [
        "education",
        "recreational",
        "social",
        "diy",
        "charity",
        "cooking",
        "relaxation",
        "music",
        "busywork"
    ]
    const [isShake, setIsShake] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [index, setIndex] = useState(0)
    const [idea, setIdea] = useState<BoredAPIResponse>(getClearResponse())
    const nodeRef = useRef(null)
    const panelRef = useRef(null)

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((index + 1) % categories.length)
        }, 100);
        return () => clearInterval(interval);
    })
    
    return (
        <>
            <div className="flex flex-col mx-10 pt-20 pb-6 justify-between min-h-screen">
                <div className='pb-20'>
                    <Illustration category={isShake ? idea.type : categories[index]} isShake={isShake}/>
                    <div>
                        <CSSTransition
                            nodeRef={nodeRef}
                            classNames="fade"
                            in={isLoaded}
                            timeout={{
                                enter: 1000,
                                exit: 800
                            }}
                            unmountOnExit
                            mountOnEnter
                        >
                            <div ref={nodeRef} className="mb-8">
                                <p className="text-base text-neutral-500">{idea.activity}</p>
                                {idea.link !== "" && 
                                    <a 
                                        className="text-sm text-pink-500 underline decoration-1" 
                                        href={idea.link} 
                                        target="_blank" 
                                        rel="noreferrer"
                                    >
                                        Learn more
                                    </a>}
                            </div>
                        </CSSTransition>
                        <CSSTransition
                            nodeRef={panelRef}
                            classNames="fade-up"
                            in={isLoaded}
                            timeout={{
                                enter: 1200,
                                exit: 200
                            }}
                            unmountOnExit
                            mountOnEnter
                        >
                            <div ref={panelRef} className="flex flex-col bg-white p-5 rounded-2xl drop-shadow-lg space-y-4">
                                <Description title='Participants' body={String(idea.participants)}/>
                                <PricePoint price={idea.price}/>
                                <AccessibilityPoint accessibility={idea.accessibility}/>
                            </div>
                        </CSSTransition>
                    </div>
                </div>
            </div>
            <div className='fixed bottom-0 left-auto max-w-screen-xs w-full px-10 pb-6 pt-10 gradient-mask'>
                { !isShake
                    ? <Button onClick={() => {
                        const _type = categories[index]
                        setIsShake(true)
                        setIdea({...idea, type : _type})
                        BoredAPI
                            .get('activity', {params: { type: _type} } )
                            .then(response => {
                                setIdea(response.data)
                                setIsLoaded(true)
                            })
                    }}>
                        SHAAAAAKE !
                    </Button>
                    : <Button onClick={() => {
                        setIsShake(false)
                        setIsLoaded(false)
                        setIdea(getClearResponse())
                    }}>
                        AN OOOTHER TRY ?
                    </Button>
                }
            </div>
        </>
    )
}

export default Home