import Capitalize from '../utils/Capitalize';
import education from 'assets/illustrations/education.svg';
import recreational from 'assets/illustrations/recreation.svg';
import social from 'assets/illustrations/social.svg';
import diy from 'assets/illustrations/diy.svg';
import charity from 'assets/illustrations/charity.svg';
import cooking from 'assets/illustrations/cooking.svg';
import relaxation from 'assets/illustrations/relaxation.svg';
import music from 'assets/illustrations/music.svg';
import busywork from 'assets/illustrations/busywork.svg';
import { CSSTransition } from 'react-transition-group';
import { useEffect, useRef } from 'react';


type IllustrationProp = {
    category: string
    isShake: boolean
}

function Illustration(props: IllustrationProp) {
    const illustrations = new Map<string, string>([
        ["education", education],
        ["recreational", recreational],
        ["social", social],
        ["diy", diy],
        ["charity", charity],
        ["cooking", cooking],
        ["relaxation", relaxation],
        ["music", music],
        ["busywork", busywork],
    ])
    const nodeRef = useRef(null)
    useEffect(() => {
        illustrations.forEach((illustration) => {
            const img = new Image()
            img.src = illustration;
        })
    })

    return (
        <>
            <CSSTransition
                nodeRef={nodeRef}
                classNames='illu'
                in={props.isShake}
                timeout={500}
                appear
            >
                <div ref={nodeRef} className="illustration flex justify-center">
                    <img className=" h-full max-w-none" src={illustrations.get(props.category)} alt={props.category} />
                </div>
            </CSSTransition>
            <h3 className="text-3xl font-bold mb-3 font-imbue">{Capitalize(props.category)}</h3>
        </>
    )
}

export default Illustration