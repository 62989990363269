import { CssVarsProvider, LinearProgress } from "@mui/joy"
import { ReactComponentLike } from "prop-types"
import { useEffect, useState } from "react"

type PropsWithSideIcons = {
    LeftIcon: ReactComponentLike,
    RightIcon: ReactComponentLike,
    valueProgressBar: number
}

function TwoIconProgressBar(props: PropsWithSideIcons) {
    const [value, setValue] = useState(0)
    useEffect(() => {
        setValue(props.valueProgressBar)
    }, [props.valueProgressBar])
    return (
        <div className="flex space-x-2">
            <props.LeftIcon className="fill-neutral-900 h-6 w-6"/>
            <div className="grow self-center">
                <CssVarsProvider>
                    <LinearProgress 
                        value={value}
                        determinate
                        size="lg"
                        thickness={12}
                        sx={{
                           color: '#E72A8B',
                           backgroundColor: '#FFF0F5'
                        }}
                    />
                </CssVarsProvider>
            </div>
            <props.RightIcon className="fill-neutral-900 h-6 w-6"/>
        </div>
    )
}

export default TwoIconProgressBar