import { useEffect, useState } from "react"
import Capitalize from "../utils/Capitalize"
import Description from "./Description"
import TwoIconProgressBar from "./TwoIconProgressBar"
import { ReactComponent as Atom }from 'assets/icons/Atom.svg'
import { ReactComponent as Book }from 'assets/icons/Book-Open.svg'

type AccessibilityPointProps = {
    accessibility: number
}

function AccessibilityPoint(props: AccessibilityPointProps) {
    const [adjective, setAdjective] = useState("")
    useEffect(() => {
        const accessibility = props.accessibility
        if (accessibility < 0.3)
            setAdjective("simple")
        else if (accessibility < 0.6)
            setAdjective("average")
        else
            setAdjective("complex")
    }, [props.accessibility])

    return (
        <div className="flex flex-col space-y-2">
            <Description
                title="Activity"
                body={Capitalize(adjective)}
            />
            <TwoIconProgressBar
                LeftIcon={Book} 
                RightIcon={Atom} 
                valueProgressBar={props.accessibility * 100}
            />
        </div>
    )
}

export default AccessibilityPoint