import { Link, useRouteError, isRouteErrorResponse } from "react-router-dom";
import notFoundSVG from 'assets/illustrations/404.svg'
import errorSVG from 'assets/illustrations/error.svg'
import Button from "components/Button";
import { useRef } from "react";

export default function Error() {
    const error = useRouteError();
    const code = useRef(500)

    if (isRouteErrorResponse(error)) {
        code.current = error.status
    }
    return (
        <div className="max-w-screen-xs mx-auto">
            <div className='flex flex-col justify-between'>
                <div className="flex flex-col max-w-screen-xs mx-auto text-center min-h-screen justify-center">
                    <img 
                        src={code.current === 404 ? notFoundSVG : errorSVG}
                        alt="error_image"
                        className="h-72"
                    />
                    <div className="mb-10 space-y-4">
                        <h1 className="text-3xl font-bold">Oops!</h1>
                        { code.current === 404
                            ? <p>Look like your're lost.</p>
                            :<p>Sorry, an unexpected error has occurred.</p>
                        }
                    </div>
                </div>
                <div className="fixed bottom-0 left-auto max-w-screen-xs w-full px-10 pb-6 pt-10 gradient-mask">
                    <Link to={'/'}>
                        <Button>Back Home</Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}