import './App.scss';
import Header from './components/Header';
// import Footer from './components/Footer';
import Home from './pages/Home';

function App() {
  return (
    <div className='App'>
      <Header />
      <div className="max-w-screen-xs mx-auto">
        <div className='flex flex-col justify-between'>
          <Home/>
          {/* <Footer/> */}
        </div>
      </div>
    </div>
  );
}

export default App;
