interface BoredAPIResponse {
    activity: string,
    accessibility: number,
    type: string,
    participants: number,
    price: number,
    link?: string,
    key: string
}

function getClearResponse(): BoredAPIResponse {
    return ({
        activity: "",
        accessibility: 0.0,
        type: "",
        participants: 0,
        price: 0.0,
        link: "",
        key: ""
    })
}

export { getClearResponse }
export default BoredAPIResponse