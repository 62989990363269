import { useEffect, useState } from "react"
import Capitalize from "../utils/Capitalize"
import Description from "./Description"
import TwoIconProgressBar from "./TwoIconProgressBar"
import { ReactComponent as Coin } from 'assets/icons/Coin.svg'
import { ReactComponent as Load } from 'assets/icons/Load.svg'

type PricePointProps = {
    price: number
}

function PricePoint(props: PricePointProps) {
    const [adjective, setAdjective] = useState("")
    useEffect(() => {
        const price = props.price
        if (price === 0)
            setAdjective("free")
        else if (price < 0.3)
            setAdjective("cheap")
        else if (price < 0.6)
            setAdjective("affordable")
        else
            setAdjective("expensive")
    }, [props.price])

    return (
        <div className="flex flex-col space-y-2">
            <Description
                title="Price"
                body={Capitalize(adjective)}
            />
            <TwoIconProgressBar
                LeftIcon={Load} 
                RightIcon={Coin} 
                valueProgressBar={props.price * 100}
            />
        </div>
    )
}

export default PricePoint