import { CssVarsProvider, Button as JoyButton} from "@mui/joy"
import { MouseEventHandler, PropsWithChildren } from "react"

interface ButtonProps extends PropsWithChildren {
    onClick?: MouseEventHandler
}

function Button(props: ButtonProps) {
    return (
        <CssVarsProvider>
            <JoyButton
                onClick={props.onClick}
                fullWidth
                size='lg'
                sx={{
                    borderRadius: '12px',
                    color: '#FFF0F5',
                    backgroundColor: '#E72A8B',
                    fontSize: '16px',
                    fontWeight: 600,
                    fontStyle: 'normal',
                    fontFamily: 'OpenSans',
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    scale: "1",
                    '&:hover': {
                        backgroundColor: '#D91077',
                    },
                    '&:active': {
                        backgroundColor: '#C10063',
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.35)",
                        scale: "0.95"
                    }
                }}
            >
                {props.children}
            </JoyButton>
        </CssVarsProvider>
    )
}

export default Button