type PropsDescription = {
    title: string,
    body: string
}

function Description(props: PropsDescription) {
    return (
        <div className="flex flex-col">
            <span className="font-bold text-xl text-left text-neutral-900 -mb-1">{props.title}</span>
            <span className="text-left text-neutral-600">{props.body}</span>
        </div>
    )
}

export default Description